(function headerBanner () {
   const isCompExists = GlobalSite.checkDependency("GlobalSite.isCompExists");
   const compName = "header-banner";
   const logFactory = GlobalSite.checkDependency("GlobalSite.logFactory");
   const Log = logFactory(`${compName}.js`);

    if (!isCompExists(compName, Log)) {
      return;
    }

    $(() => {
      let amountWidth;
      const euroSymbol = document.getElementById('input-symbol');
      const inputField = document.getElementById('input_field-input');
      const btnSimulate = document.querySelector('#widget-simu-button');
      let isValid = false;

      const clearButton = document.getElementById('clear-button');
      const validCircleIcon = document.querySelector('.i-check-circle-fill');
      const errorIcon = document.getElementById('error-icon');

      const errorMessageMin = document.querySelector('.input-block__message--min');
      const errorMessageMax = document.querySelector('.input-block__message--max');

      const calculateTextLength = (content, fontFamily, fontSize) => {
        const tempElement = document.createElement('span');
        tempElement.style.fontFamily = fontFamily;
        tempElement.style.fontSize = fontSize;
        tempElement.style.visibility = 'hidden';
        tempElement.style.whiteSpace = 'pre';
        tempElement.textContent = content;

        document.body.appendChild(tempElement);
        const textWidth = tempElement.getBoundingClientRect().width;
        document.body.removeChild(tempElement);

        return placeholderWidth = textWidth;
      }

      const formatValue = (value) => {
        if (value.trim() === '') {
          return ''; 
        }
        let numericValue = parseFloat(value.replace(/\D/g, '')); 
        return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") 
      }

      const formatAndStickInputSymbolToAmount = () => {
        const computedStyles = getComputedStyle(inputField);
        const fontSize = computedStyles.getPropertyValue('font-size');
        const fontFamily = computedStyles.getPropertyValue('font-family');

        let formattedValue = formatValue(inputField.value);

        if (formattedValue) {
          inputField.value = formattedValue;

          let textWidth = calculateTextLength(formattedValue, fontFamily, fontSize);
          amountWidth = (textWidth + 34) + 'px';

          clearButton.style.display = 'block';

          let numericValue = formattedValue ? parseFloat(formattedValue.replace(/\D/g, '')) : 0;

          if (numericValue >= 500 && numericValue <= 75000) {
            isValid = true;

            validCircleIcon.style.display = 'block';
            errorIcon.style.display = 'none';

            inputField.classList.add('is-valid');
            inputField.classList.remove('error');

            errorMessageMin.style.display = 'none';
            errorMessageMax.style.display = 'none';

            inputField.setAttribute('aria-invalid', 'false');

            inputField.removeAttribute('aria-describedby');
          } else {
            isValid = false;

            errorIcon.style.display = 'block';
            validCircleIcon.style.display = 'none';

            inputField.classList.add('error');
            inputField.classList.remove('is-valid');

            inputField.setAttribute('aria-invalid', 'true');

            if (numericValue < 500) {
              errorMessageMin.style.display = 'block';
              errorMessageMax.style.display = 'none';

              inputField.setAttribute('aria-describedby', 'error-message-min')

            } else if (numericValue > 75000) {
              errorMessageMin.style.display = 'none';
              errorMessageMax.style.display = 'block';

              inputField.setAttribute('aria-describedby', 'error-message-max')
            }
          }
        } else {
          let placeholderWidth = calculateTextLength(inputField.placeholder, fontFamily, fontSize);
          amountWidth = (placeholderWidth + 22) + 'px';

          clearButton.style.display = 'none';
          validCircleIcon.style.display = 'none';
          errorIcon.style.display = 'none';

          inputField.classList.remove('error');
          inputField.classList.remove('is-valid');

          inputField.removeAttribute('aria-invalid');
          inputField.removeAttribute('aria-describedby');
        }

        euroSymbolActivate(formattedValue);

        euroSymbol.style.left = amountWidth;
      }

      const euroSymbolActivate = (value) => {

        if(value){
          euroSymbol.classList.add('is-active');
        }else{
          euroSymbol.classList.remove('is-active');
        }
      }

      const clearAmount = () => {
        inputField.value = '';
        euroSymbol.style.left = '2rem';
        clearButton.style.display = 'none';
        validCircleIcon.style.display = 'none';
        errorIcon.style.display = 'none';
        errorMessageMin.style.display = 'none';
        errorMessageMax.style.display = 'none';

        inputField.classList.remove('error');
        inputField.classList.remove('is-valid');

               euroSymbol.classList.remove('is-active');

        inputField.setAttribute('aria-invalid', 'false');
      }

      inputField.addEventListener('input', formatAndStickInputSymbolToAmount);
      clearButton.addEventListener('click', clearAmount);

      inputField.addEventListener('keypress', function(e) {
        if ((e.which < 48 || e.which > 57)
            && (e.key < "0" || e.key > "9"))
          {
          e.preventDefault();
        }
      })

      btnSimulate.addEventListener('click', (e) => {
        e.preventDefault();

        const checkHasQueryPams = (url) => new URL(url).searchParams.size > 0 || url.indexOf('?') !== -1;

        const btnSimulateHref = btnSimulate.href;
        const simulateValue = formatValue(inputField.value).replace(/\D/g, '');
        const redirectTo = `${btnSimulateHref}${isValid ? `${checkHasQueryPams(btnSimulateHref) ? '&' : '?'}goodAmount=${simulateValue}` : ''}`;

        window.location.replace(redirectTo);
      })


      const generateStars = () => {
        const gradeValue = parseFloat(document.querySelector(".header-banner .header-banner__reviews #grade-value-header-banner > span")?.textContent.replace(/,/g, '.'))
        const container = document.querySelector(".header-banner #rating-stars-header-banner");

        if (gradeValue && container) {
          const percentage = (gradeValue / 5) * 100

          container.innerHTML = ''; 

          const totalStars = 5; 
          const fullStars = Math.floor((percentage > 100 ? 100 : percentage) / 20); 
          const remainderPercentage = ((percentage > 100 ? 100 : percentage) % 20) * 5; 

          const starSVG = `
            <svg class="star-svg" xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17">
              <path d="M4.13499 9.67406L0.731328 7.46515C0.00995603 6.99496 0.350322 5.90764 1.2241 5.90764H5.37961C5.76061 5.90764 6.0959 5.67255 6.21782 5.3248L7.51832 1.56818C7.78756 0.79433 8.92551 0.79433 9.18967 1.56818L10.4902 5.3248C10.6121 5.67255 10.9474 5.90764 11.3284 5.90764H15.4687C16.3424 5.90764 16.6828 6.99986 15.9563 7.46515L12.5324 9.67406C12.2022 9.88466 12.0599 10.2863 12.1869 10.6487L13.5128 14.4837C13.7871 15.2723 12.8422 15.9335 12.1463 15.4339L8.85946 13.0731C8.5445 12.8478 8.11777 12.8478 7.8028 13.0731L4.516 15.4339C3.8251 15.9335 2.88021 15.2723 3.14945 14.4837L4.47535 10.6487C4.60236 10.2863 4.46011 9.88956 4.1299 9.67406" />
            </svg>
          `;

          for (let i = 0; i < fullStars; i++) {
            container.innerHTML += `
              <div class="star full">
                ${starSVG}
              </div>
            `;
          }

          if (fullStars < totalStars) {
            container.innerHTML += `
              <div class="star partial" style="--star-remainder-percentage-header-banner: ${remainderPercentage}%;">
                ${starSVG}
              </div>
            `;
          }
        }
      }

      generateStars();
    })
})()